<!--  -->
<template>
  <div class="container">
    <div class="List-title">
      <span :style="{'color':open=='01'?'#00a5ff':'#222'}" @click="changeList('01')" >待入园</span>
      <span :style="{'color':open=='02'?'#00a5ff':'#222'}" @click="changeList('02')" >已入园</span>
      <!-- <span :style="{'color':open=='03'?'#00a5ff':'#222'}" @click="changeList('03')" >已取消</span> -->
      <span :style="{'color':open=='04'?'#00a5ff':'#222'}" @click="changeList('04')" >全部</span>   
      <div :class="['ac_block'+open, ' block' ]"></div> 
    </div> 
    <!-- 待入园 -->
    <div v-if="open=='01'" class="orderList">
      <van-list
        v-model="loading1"
        :finished="finished1"
        finished-text="没有更多了"
        @load="onLoad1"
        v-if="iscontainer1"
      >
        <div v-for="(item,index) in  list1" :key='index' class="orderList-li orderList-li2" @click="openDetail(item)">
          <div class="li-data"> 下单时间：{{item.orderDate}}</div>
          <span class="li-status  li-status2">待入园</span>
          <div class="info-li">
            <span>购票人姓名</span>
            <span>{{item.consignee}}</span>
          </div>
          <div class="info-li">
            <span>手机号</span>
            <span>{{item.mobile}}</span>
          </div>
          <div class="info-li">
            <span>购票数量</span>
            <span>{{item.ticketNumber}}</span>
          </div>
          <div class="info-li">
            <span>入园日期</span>
            <span>{{item.ticketDate.replace('00:00:00','')}}</span>
          </div>
          <div class="info-li">
            <span>金额</span>
            <span>¥ {{item.orderPrice}}</span>
          </div>
          <!-- <div class="li-btn" @click="orderbtn(item.orderSn)">退票</div> -->
        </div>
      </van-list>
      <div class="body-view" v-if="!iscontainer1">
        <img src="../../static/null.png">
        <p>暂无数据</p>
      </div>
    </div>
    <!-- 已入园 -->
    <div v-if="open=='02'" class="orderList">
      <van-list
        v-model="loading2"
        :finished="finished2"
        finished-text="没有更多了"
        @load="onLoad2"
        v-if="iscontainer2"
      >
        <div v-for="(item,index) in  list2" :key='index' class="orderList-li">
          <div class="li-data"> 下单时间：{{item.orderDate}}</div>
          <span class="li-status  li-status2">已入园</span>
          <div class="info-li">
            <span>购票人姓名</span>
            <span>{{item.consignee}}</span>
          </div>
          <div class="info-li">
            <span>手机号</span>
            <span>{{item.mobile}}</span>
          </div>
          <div class="info-li">
            <span>购票数量</span>
            <span>{{item.ticketNumber}}</span>
          </div>
          <div class="info-li">
            <span>入园日期</span>
            <span>{{item.ticketDate.replace('00:00:00','')}}</span>
          </div>
          <div class="info-li">
            <span>金额</span>
            <span>¥ {{item.orderPrice}}</span>
          </div>
        </div>
      </van-list>
      <div class="body-view" v-if="!iscontainer2">
        <img src="../../static/null.png">
        <p>暂无数据</p>
      </div>
    </div>
    <!-- 已取消 -->
    <!-- <div v-if="open=='03'" class="orderList">
      <van-list
        v-model="loading3"
        :finished="finished3"
        finished-text="没有更多了"
        @load="onLoad3"
        v-if="iscontainer3"
      >
        <div v-for="(item,index) in  list3" :key='index' class="orderList-li">
          <div class="li-data"> 下单时间：{{item.orderDate}}</div>
          <span class="li-status ">已取消</span>
          <div class="info-li">
            <span>购票人姓名</span>
            <span>{{item.consignee}}</span>
          </div>
          <div class="info-li">
            <span>手机号</span>
            <span>{{item.mobile}}</span>
          </div>
          <div class="info-li">
            <span>购票数量</span>
            <span>{{item.ticketNumber}}</span>
          </div>
          <div class="info-li">
            <span>入园日期</span>
            <span>{{item.ticketDate}}</span>
          </div>
          <div class="info-li">
            <span>金额</span>
            <span>¥ {{item.orderPrice}}</span>
          </div>
        </div>
      </van-list>
      <div class="body-view" v-if="!iscontainer3">
        <img src="../../static/null.png">
        <p>暂无数据</p>
      </div>
    </div> -->
    <!-- 全部 -->
    <div v-if="open=='04'" class="orderList">
      <van-list
        v-model="loading4"
        :finished="finished4"
        finished-text="没有更多了"
        @load="onLoad4"
        v-if="iscontainer4"
      >
        <div v-for="(item,index) in  list4" :key='index' class="orderList-li"  @click="openDetail(item)">
          <div class="li-data"> 下单时间：{{item.orderDate}}</div>
          <span  :class="{'li-status':'li-status','li-status2':item.ticketStatus!='2'}">{{filter(item.ticketStatus)}}</span>
          <div class="info-li">
            <span>购票人姓名</span>
            <span>{{item.consignee}}</span>
          </div>
          <div class="info-li">
            <span>手机号</span>
            <span>{{item.mobile}}</span>
          </div>
          <div class="info-li">
            <span>购票数量</span>
            <span>{{item.ticketNumber}}</span>
          </div>
          <div class="info-li">
            <span>入园日期</span>
            <span>{{item.ticketDate.replace('00:00:00','')}}</span>
          </div>
          <div class="info-li">
            <span>金额</span>
            <span>¥ {{item.orderPrice}}</span>
          </div>
          <!-- <div class="li-btn" @click="orderbtn(item.orderSn)" v-show="item.ticketStatus=='0'">退票</div> -->
        </div>
      </van-list>
      <div class="body-view" v-if="!iscontainer4">
        <img src="../../static/null.png">
        <p>暂无数据</p>
      </div>
    </div>
  </div>
</template>

<script>
import { List , Toast,Dialog  } from 'vant';
export default {
    data () {
        return {
          info:{},
          contentId:'',
          open:'01',
          openId:'',         
          isPlayAudio:false,
          audioSeek:0,
          audioTime:0,
          audioDuration: 0,
          list1:[],
          list2:[],
          list3:[],
          list4:[],
          loading1: false,
          finished1: false,
          iscontainer1:true,
          loading2: false,
          finished2: false,
          iscontainer2:true,
          loading3: false,
          finished3: false,
          iscontainer3:true,
          loading4: false,
          finished4: false,
          iscontainer4:true,
          page1: 0,
          page2: 0,
          page3: 0,
          page4: 0,
          size:10,
        };
    },
    components: {
      [List.name]: List,
      [Toast.name]: Toast,
      [Dialog.Component.name]: Dialog.Component
    },
    created() {
        this.openId = this.$route.query.openId;
        // this.contentId = this.$route.query.contentId;
        // this.getRecords("min-introduce-spot");
    },
    computed: {
      
    },
    mounted(){
      this.onLoad2();
      this.onLoad3();
      this.onLoad4();  
    },
    methods: {
      // 改变bar
      changeList(id) {
        this.open=id;
        var scrolltop = document.documentElement.scrollTop || document.body.scrollTop;
        document.documentElement.scrollTop = document.body.scrollTop =0; 
      },
      onLoad1() {
        if(!this.openId){
          this.iscontainer1 = false;
          return 
        }
        // 异步更新数据
        this.page1 +=1;
        let postData= {
          openid: this.openId,
          orderStatus:'1',
          ticketStatus:'0',
          page: this.page1,
          size: this.size,
        }       
        this.$ajax.get('https://wx.tslbrsr.com/tb/order/list', {
          params:postData
        })
        .then(res=> {
          // 成功
          this.loading1 = false;
          if (res.data.data.list.length == 0) {
              this.iscontainer1 = false;
          } else {
              this.list1 =this.list1.concat(res.data.data.list);
              if (res.data.data.list.length < 10) {
                  this.finished1= true;
              }
          }; 
        })
        .catch( (error)=> {
          console.log(error);
        });      
      },
      onLoad2() {
        if(!this.openId){
          this.iscontainer2 = false;
          return 
        }
        // 异步更新数据
        this.page2 +=1;
        let postData= {
          openid: this.openId,
          orderStatus:'1',
          ticketStatus:'1',
          page: this.page2,
          size: this.size,
        }      
        this.$ajax.get('https://wx.tslbrsr.com/tb/order/list', {
          params:postData
        })
        .then(res=> {
          // 成功
          this.loading2 = false;
          if (res.data.data.list.length == 0) {
              this.iscontainer2 = false;
          } else {
              this.list2 =this.list2.concat(res.data.data.list);
              if (res.data.data.list.length < 10) {
                  this.finished2= true;
              }
          }; 
        })
        .catch( (error)=> {
          console.log(error);
        });      
      },
      onLoad3() {
        if(!this.openId){
          this.iscontainer3 = false;
          return 
        }
        // 异步更新数据
        this.page3 +=1;
        let postData= {
          openid: this.openId,
          orderStatus:'1',
          ticketStatus:'2',
          page: this.page3,
          size: this.size,
        }      
        this.$ajax.get('https://wx.tslbrsr.com/tb/order/list', {
          params:postData
        })
        .then(res=> {
          // 成功
          this.loading3 = false;
          if (res.data.data.list.length == 0) {
              this.iscontainer3 = false;
          } else {
              this.list3 =this.list3.concat(res.data.data.list);
              if (res.data.data.list.length < 10) {
                  this.finished3= true;
              }
          }; 
        })
        .catch( (error)=> {
          console.log(error);
        });      
      },
      onLoad4() {
        if(!this.openId){
          this.iscontainer4 = false;
          return 
        }
        // 异步更新数据
        this.page4 +=1;
        let postData= {
          openid: this.openId,
          orderStatus:'1',
          page: this.page4,
          size: this.size,
        }      
        this.$ajax.get('https://wx.tslbrsr.com/tb/order/list', {
          params:postData
        })
        .then(res=> {
          // 成功
          this.loading4 = false;
          if (res.data.data.list.length == 0) {
              this.iscontainer4 = false;
          } else {
            this.list4 =this.list4.concat(res.data.data.list);
            if (res.data.data.list.length < 10) {
                this.finished4= true;
            }
          }; 
        })
        .catch( (error)=> {
          console.log(error);
        });      
      },
      filter(status){
        if(status=='1'){
          return '已入园'
        }else if(status=='2'){
          return '已取消'
        }else if(status=='0'){
          return '待入园'
        }else{
          return '待支付'
        }
      },
      orderbtn(id){       
        Dialog.confirm({
          title: '您确定要退票么？',
        }).then(() => {
          // 确定
          let postData= {
            openid: this.openId,
            orderSn:id
          }   
          this.$ajax.get('https://wx.tslbrsr.com/tb/order/cancel', {
            params:postData
          })
          .then(res=> {
            // 成功
            console.log(res);
            if(res.data.resultCode=='200'){
              this.page1=0;
              this.list1=[];
              this.onLoad1();
              // Toast('退票成功');
               this.$router.push({
                  path: "/success", 
                  query: {
                      type: 2
                  }             
                });
              // 刷新全部
              this.page4=0;
              this.list4=[];
              this.onLoad4();
            }else{
              Toast('退票失败');
            }
          })
        .catch( (error)=> {
          console.log(error);
        }); 
        }).catch(() => {
           Toast('取消');
        });
      },
      // 打开入园凭证
      openDetail(item){
        if(item.ticketStatus=='0'){
          this.$router.push({
            path: "/orderDetail", 
            query: {
              id: item.orderSn
            }             
          });
        }
      }

      
    }, 
}

</script>
<style scoped>
.container{
  width: 100vw;
  height: 100vh;
  background: #F7F7F7;
}
.List-title {
  width: 10rem;
  height: 1.12rem;
  border-bottom: 1px solid #c7c7c7;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10;
  background: #fff;
  line-height: 1.12rem;
}
.List-title span {
  width: 3.3rem;
  height: 1.12rem;
  font-size: 0.4rem;
  color: #222;
  line-height: 1.12rem;
  display: inline-block;
  text-align: center;
}
.active {
  color: #00a5ff;
}

.block {
  position: absolute;
  width:1.2rem;
  height: 2px;
  background: #00a5ff;
  bottom: -0.026rem;
  left: 1.06rem;
  transition: all 0.4s;
}

.ac_block02 {
  left: 4.4rem;
}

.ac_block03 {
  left: 6.6rem;
}

.ac_block04 {
  left: 7.7rem;
}
.orderList{
  width: 10rem;
  padding: 1.546rem 0.346rem 0  0.346rem;
  box-sizing: border-box;
  overflow: hidden;
}
.orderList .orderList-li:first-child{
  margin-top:0;
}
.orderList-li{
  width: 100%;
  padding: 0 0.56rem;
  box-sizing: border-box;
  height: 5.386rem;
  margin-top: 0.426rem;
  background: url(../../static/order2.png) no-repeat 0 0 ;
  background-size:100% 100%; 
  position: relative;
}
.orderList-li2{
  height: 5.97rem;
  background: url(../../static/order.png) no-repeat 0 0 ;
  background-size:100% 100%; 
}
.li-data{
  font-size: 0.346rem;
  padding-top:0.426rem;
  text-indent: 0.613rem;
  position: relative;
  color: #888888;
}
.li-data::before{
  position: absolute;
  content: '';
  width: 0.426rem;
  height: 0.426rem;
  background: url(../../static/order_icon.jpg) no-repeat 0 0 ;
  background-size:100% 100%; 
  left: 0;
  top: 0.426rem;
}
.li-status{
  font-size: 0.346rem;
  color:#888888;
  position: absolute;
  right: 0.56rem;
  top: 0.426rem;
}
.li-status2{
  color: #00A5FF;
}
.info-li{
  height: 0.533rem;
  margin-top: 0.106rem;
  font-size: 0.373rem;
  line-height: 0.533rem;
}
.info-li span:first-child{
  width: 2.4rem;
  display: inline-block;
  color: #888888;
}
.info-li span:nth-child(2){
  color: #222;
}
.orderList-li .info-li:nth-child(3){
  margin-top:0.8rem;
}
.li-btn{
  width: 1.81rem;
  height:0.746rem ;
  text-align: center;
  font-size: 0.346rem;
  line-height: 0.746rem ;
  color:#00A5FF;
  border:1px solid rgba(0,165,255,1);
  border-radius:0.05rem;
  position: absolute;
  right: 0.56rem;
  bottom: 0.5rem;
}
.body-view{
  width: 100vw;
  position: relative;
  left: -0.346rem;
  top: -0.426rem;
}
.body-view img{
  width: 3.16rem;
  height:  3.16rem;
  margin: 4rem 0 0.32rem 3.2rem;
}
.body-view p{
  font-size: 0.373rem;
  color: #888888;
  line-height: 0.53rem;
  text-align: center
}
</style>